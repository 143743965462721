import React from "react"
import Layout from "../components/layout"
import Seamless from "../components/SeamlessEmbed"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import { getPostObj } from "../utils/utils"

import { graphql } from "gatsby"

const GetStartedPage = ({ pageContext, location, data }) => {
  var post
  var language
  let postObj = getPostObj(
    pageContext,
    data.allUniquePagesJson.nodes[0],
    data.allSpanishUniquePagesJson.nodes[0]
  )
  post = postObj.post
  language = postObj.language

  return (
    <SharedStateProvider>
      <Layout layoutClass={"contact-fab"} language={language}>
        <SEO
          lang={language}
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={location.pathname}
          schemaPage={post.schemaPage ? post.schemaPage : null}
        />
        <div className="joshua-tree-content">
          <div className="columns">
            <div className="column">
              <div
                className="columns top-section text-section is-mobile white-back"
                style={{ paddingBottom: "48px" }}
              >
                <div className="column is-hidden-mobile is-4-tablet"></div>
                <div className="column">
                  <div>
                    <h1>{post.heading}</h1>
                    <p className="large">{post.blurb}</p>
                  </div>
                </div>
                <div className="column is-hidden-mobile is-4-tablet"></div>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <div
                className="columns body-section text-section is-mobile form-area"
                style={{ paddingTop: "0" }}
              >
                <div className="column is-1-mobile is-5-tablet"></div>
                <div className="column">
                  <div>
                    <h5>
                      {language === "es"
                        ? "Formulario de Contacto para Pacientes Nuevos"
                        : "New Patient Contact Form"}
                    </h5>
                    <Seamless
                      id={
                        language === "es"
                          ? "abb222d68bafe064118751f728654f1b"
                          : "2736910a3a28591dc19b9d8209f7d55b"
                      }
                    />
                  </div>
                </div>
                <div className="column is-1-mobile is-5-tablet"></div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query getStartedPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        schemaPage
        blurb
      }
    }
    allSpanishUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        schemaPage
        blurb
      }
    }
  }
`

export default GetStartedPage
